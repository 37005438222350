import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import consult from '../assets/images/consult_res.jpg'
import get_together from '../assets/images/get_together.jpg'
import meeting from '../assets/images/meeting.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>GetTheBits</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Who are we</h2>
                    </header>
                    <p>Our custom software development company delivers remarkable results, which generate immediate value for your business. On time. Up to your specs. According to the budget. We offer creative, elegant and cost-efficient custom software solutions even for the most complex business problems. By partnering with GetTheBits, you experience the benefits of a high-performing in-house team with no overhead costs involved. Our custom software developers and PMs will closely work with at every project stage to deliver sub par outcome.</p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="#" className="image">
                        <img src={get_together} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Your online presence matters</h3>
                            </header>
                            <p>Your website is an extension of your business, and many times is the first impression someone will have of your company. An outdated or ineffective website can be detrimental to your bottom line, if not a terrible reflection of your business, team, and products or services. Think of your site's visitors as people walking through the front door of your company.
What type of experience do you want them to have?</p>
                            {/* <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul> */}
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="#" className="image">
                        <img src={consult} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Get to cloud</h3>
                            </header>
                            <p>GetTheBits will provide professional consulting services to explore cloud migration feasibility, options and the best approach. Migrations are only the beginning when it comes to your business taking advantage of the cloud. Whatever your business case may be, we’ll help define your cloud roadmap, plan and execute your migration to the cloud, then work with you to take advantage of your new cloud-based solutions.</p>
                            {/* <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul> */}
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="#" className="image">
                        <img src={meeting} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Custom solutions</h3>
                            </header>
                            <p>If there is one thing we do best, it is creating business solutions with technology. Every software project starts with an idea. Our team will work with you to shape your ideas into something even better for your users.</p>
                            {/* <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul> */}
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing